<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {
  shopServiceMethods,
  notificationMethods
} from "@/state/helpers";

import {
  required, 
} from "vuelidate/lib/validators";

import { alertService } from '../../../services/alert.service';

const initForm = {message: "",createdBy: JSON.parse(localStorage.getItem('user')).id};

export default {
  page: {
    title: "Alerts ",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      tableData: [],
      title: "Alerts",
      items: [
        {
          text: "Smart Exchange",
          href: "/"
        },
        {
          text: "Alerts",
          active: true
        }
      ],
      selectedAlert:null,
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true },
        { key: "message", sortable: true },
        { key: "alertType", sortable: true },
        { key: "actions"}
      ],
      form: {
        id:null,
        message: null,
        createdBy: JSON.parse(localStorage.getItem('user')).id,
      },
      submitted: false,
      submitform: false,
      submit: false,
      showform: false
    };
  },

  validations: {
    form: {
      id:{ required },
      message: { required }
    },
  },

  created() {
    this.loadAllAlerts();
  },

  computed: {
    rows() {
      return this.tableData.length;
    },

    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },

  mounted() {
    this.totalRows = this.items.length;
  },

  methods: { 
    ...shopServiceMethods,
    ...notificationMethods,

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    formSubmit() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitted = false;
        return;
      } else {
          var {form} = this;
          form.message = form.message.replace('@receiver','{receiverFirstName} {receiverLastName}')
                      .replace('@sender', '{senderFirstName} {senderLastName}')
                      .replace('@code', '{referenceCode}')
                      .replace('@amount', '{amount}')

          const alertForm = {
                "operation" : "alertCreation",
                "accessToken" : JSON.parse(localStorage.getItem('access_token')),
                "requestBody" : form
          }; 

          alertService.editAlert(alertForm).then(result=>{
            if(result.status=="SUCCESS"){
              this.showform = false;
              this.loadAllAlerts();
              this.notificationType = "success";
              this.noticationIcon = "mdi-check-all";
              this.submitted = false;
              this.form = Object.assign({}, initForm);
            } else {
              this.submitted = false;
              this.notificationType = "danger";
              this.noticationIcon = "mdi-block-helper";
            }
          });
      }
    },

    async loadAllAlerts() {
        try {
        await alertService.getAllAlerts().then(response=>{
            if(response.responseBody.length>0){
                this.tableData = response.responseBody;
              }
          });
        } catch (error) {
          console.log(error);
        }
    },

    storeState(message){
      this.showform = true;
      this.selectedAlert = message
      this.form.message = message.item.message
                      .replace('{receiverFirstName} {receiverLastName}', '@receiver')
                      .replace('{senderFirstName} {senderLastName}', '@sender')
                      .replace('{referenceCode}','@code')
                      .replace('{amount}', '@amount')
                      .replace('{senderFirstName}', '@sender')
                      .replace('{receiverFirstName}', '@receiver')
      this.form.id = message.item.id;
    }
  },

};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-8">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
        
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(message)="row">
                  {{
                  row.item.message
                      .replace('{receiverFirstName} {receiverLastName}', '@receiver')
                      .replace('{senderFirstName} {senderLastName}', '@sender')
                      .replace('{referenceCode}','@code')
                      .replace('{amount}', '@amount')
                      .replace('{senderFirstName}', '@sender')
                      .replace('{receiverFirstName}', '@receiver')
                  }}
                </template>
                <template #cell(actions)="row">
                  <a
                    @click="storeState(row)"
                    href="javascript:void(0);"
                    class="mr-3 text-primary"
                    v-b-tooltip.hover
                    title="Edit"
                  >
                    <i v-b-modal.modal-standard class="mdi mdi-pencil font-size-18" ></i>
                  </a>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-4">
        <b-alert
          :variant="notificationType" 
          class="mt-3"
          v-if="notification.message"
          show
          dismissible
        >
          <i :class="'mdi '+ noticationIcon + ' mr-2'"></i>{{notification.message}}
        </b-alert>
        <div class="card" v-if="showform">
          <div class="card-body">
            <form class="needs-registration" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label >Use @sender and @receiver as placeholders where names should go</label><br/>
                    <label >Use @amount as a placeholder for amount</label><br/>
                    <label >Use @code as a placeholder for the reference code</label>
                  </div>
                </div>

              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom03">Message </label>
                      <textarea
                        id="validationCustom03"
                        v-model="form.message"
                        rows="7"
                        type="text"
                        class="form-control"
                        placeholder="Enter the message"
                        :class="{ 'is-invalid': submitted && $v.form.message.$error }"
                      />
                    <div v-if="submitted && $v.form.message.$error" class="invalid-feedback">
                      <span v-if="!$v.form.message.required">This value is required.</span> 
                    </div>
                    <div v-if="submitted && $v.form.id.$error" class="invalid-feedback">
                      <span v-if="!$v.form.id.required">Failed to set the message ID.</span> 
                    </div>
                  </div>
                </div>
              </div>
              <div class="spinner" v-if="submitted">
                <i class="ri-loader-line spin-icon"></i>
              </div>
              <button v-if="!submitted" class="btn btn-primary" type="submit">Edit Message</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>